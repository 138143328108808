import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/keyboard";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { loadableReady } from "@loadable/component";
import { Provider } from "react-redux";
import store from "Redux/store";
import { isBrowser } from "Utils/isBrowser";
import { App } from "Components/App/App";
import { ErrorBoundary } from "Components/ErrorBoundary/ErrorBoundary";
import { ErrorUi } from "Components/ErrorUi/ErrorUi";
var localCart = localStorage.getItem("cart");
localCart &&
    (localCart.indexOf("wishlist_count") > -1 || localCart.indexOf("items") > -1) &&
    localStorage.removeItem("cart");
var container = document.getElementById("root");
var clientRoot = createRoot(container);
var msg_main = "";
var msg_sub = "";
if (isBrowser()) {
    if (window._ES6NotSupported ||
        (!window.IntersectionObserver && !window.IntersectionObserverEntry) ||
        !window.ResizeObserver ||
        !window.AbortController ||
        !window.MediaQueryList) {
        msg_main = "В вашем браузере отсутствует поддержка необходимых компонентов.";
        msg_sub = "Попробуйте обновить ваш браузер.";
    }
    else if (typeof localStorage === "object") {
        try {
            localStorage.setItem("localStorage", "1");
            localStorage.removeItem("localStorage");
        }
        catch (e) {
            msg_main =
                "Ваш веб-браузер не поддерживает работу с локальным сохранением настроек. Например, в Safari наиболее частой причиной этого является использование «Режима приватного просмотра».";
            msg_sub = "Попробуйте открыть вкладку в обычном режиме.";
        }
    }
    else {
        msg_main = "В вашем браузере отключена работа с локальным сохранением настроек.";
        msg_sub = "Возобновите работу localStorage.";
    }
}
if (msg_main) {
    clientRoot.render(React.createElement(ErrorUi, { msg_main: msg_main, msg_sub: msg_sub }));
}
else {
    var app_1 = (React.createElement(Provider, { store: store },
        React.createElement(ErrorBoundary, null,
            React.createElement(App, null))));
    loadableReady(function () {
        if (container === null || container === void 0 ? void 0 : container.hasChildNodes()) {
            hydrateRoot(container, app_1);
        }
        else {
            clientRoot.render(app_1);
        }
    });
}
