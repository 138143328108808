import { dataLayer } from "Static/variables";
export var sendGTMAnaliticsOnProductClick = function (_a) {
    var list = _a.list, productsForSend = _a.productsForSend, fullProductsList = _a.fullProductsList;
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        currencyCode: "RUB",
        ecommerce: {
            click: {
                actionField: { list: list },
                products: productsForSend.map(function (product, idx) {
                    var _a;
                    return ({
                        brand: ((_a = product.brand) === null || _a === void 0 ? void 0 : _a.name) || product.brand,
                        category: product.category_name || product.category.name,
                        id: product.id,
                        name: "".concat(product.model).concat(product.model_full ? " " + product.model_full : ""),
                        position: fullProductsList.findIndex(function (currentProduct) { return currentProduct.id === product.id; }) + 1 || idx + 1,
                        price: product.actual_price,
                        variant: product.sku,
                    });
                }),
            },
        },
    });
};
