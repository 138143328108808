var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
var _b;
import { createSlice } from "@reduxjs/toolkit";
import { getBrowserName } from "Utils/getBrowserName";
import { getQueryVariableFromUrl } from "Utils/getQueryVariable";
import { getWindowWidth } from "Utils/getWindowWidth";
import localStorage from "Utils/localStorage";
import { isBrowser } from "Utils/isBrowser";
import { getGenderDataFromPathname } from "Utils/getGenderDataFromLocation";
import { isValidGenderNumber } from "Utils/isValidGenderNumber";
import { personalAreaRoutes } from "Static/personalAreaRoutes";
var isBrowserEnv = isBrowser();
var initialGender = { gender: "все", genderNumber: "0" };
var queryGender = isBrowserEnv ? getQueryVariableFromUrl(location.pathname + location.search, "sex") : null;
var localGender = localStorage.getItem("genderData");
if (queryGender && !isValidGenderNumber(queryGender))
    queryGender = null;
if (localGender && !isValidGenderNumber((_b = JSON.parse(localGender)) === null || _b === void 0 ? void 0 : _b.genderNumber)) {
    localGender = JSON.stringify(initialGender);
    localStorage.setItem("genderData", JSON.stringify(initialGender));
}
var queryGenderData = { gender: "все", genderNumber: queryGender || "0" };
switch (queryGender) {
    case "0":
        queryGenderData.gender = "все";
        break;
    case "1":
        queryGenderData.gender = "мужское";
        break;
    case "2":
        queryGenderData.gender = "женское";
        break;
}
var pathnameGender = isBrowserEnv ? getGenderDataFromPathname(location.pathname) : initialGender;
if (queryGender) {
    initialGender = queryGenderData;
    localStorage.setItem("genderData", JSON.stringify(queryGenderData));
}
else if (pathnameGender.genderNumber !== "0") {
    initialGender = pathnameGender;
    localStorage.setItem("genderData", JSON.stringify(pathnameGender));
}
else if (localGender) {
    initialGender = JSON.parse(localGender);
}
var savedCity = localStorage.getItem("city");
var initialCity = savedCity ? JSON.parse(savedCity) : { id: "992", name: "Москва" };
var savedNotifications = localStorage.getItem("notifications");
var initialNotifications = savedNotifications ? JSON.parse(savedNotifications) : [];
var localUnvisitedOrders = localStorage.getItem("unvisitedOrders");
var initialUnvisitedOrders = localUnvisitedOrders ? +localUnvisitedOrders : 0;
var windowWidth = isBrowserEnv ? getWindowWidth() : 1920;
var initialState = {
    AB_tests: null,
    appNotify: false,
    breakpointMatches: {
        1440: windowWidth <= 1440,
        1080: windowWidth <= 1080,
        960: windowWidth <= 960,
        768: windowWidth <= 768,
        600: windowWidth <= 600,
        374: windowWidth <= 374,
    },
    city: initialCity,
    clientWidgetSubClass: "",
    defaultFooterLinks: [],
    deviceInfo: { deviceType: "", deviceOS: "", browserName: "" },
    discountIncrease: null,
    footerLinks: [],
    genderData: initialGender,
    isAuthModalShown: false,
    isFlexGapSupported: false,
    isFooterIntersected: false,
    isFriendlyIp: true,
    isHeaderShown: isBrowserEnv
        ? location.pathname.startsWith("/checkout") ||
            (personalAreaRoutes.includes(location.pathname) && windowWidth <= 960)
            ? false
            : true
        : true,
    mainMenu: [],
    notifications: initialNotifications,
    optionalBlocks: [],
    promoActions: [],
    showFooter: true,
    snackbarData: null,
    unvisitedOrders: initialUnvisitedOrders,
};
export var appSlice = createSlice({
    name: "app",
    initialState: initialState,
    reducers: {
        saveMainMenu: function (state, action) {
            state.mainMenu = action.payload;
        },
        changeGender: function (state, action) {
            var newGender = action.payload;
            if (!newGender) {
                state.genderData = initialGender;
                localStorage.setItem("genderData", JSON.stringify(initialGender));
            }
            else if (state.genderData.genderNumber !== newGender.genderNumber) {
                state.genderData = newGender;
                localStorage.setItem("genderData", JSON.stringify(newGender));
            }
        },
        identifyDevice: function (state) {
            var userDeviceArray = [
                { device: "Android", platform: /Android/ },
                { device: "iPad", platform: /iPad/ },
                { device: "iPhone", platform: /iPhone/ },
                { device: "Linux", platform: /Linux/ },
                { device: "Macintosh", platform: /Macintosh/ },
                { device: "Symbian", platform: /Symbian/ },
                { device: "Tablet OS", platform: /Tablet OS/ },
                { device: "Windows Phone", platform: /Windows Phone/ },
                { device: "Windows", platform: /Windows NT/ },
            ];
            var currentPlatform = window.navigator ? window.navigator.userAgent : "";
            var device = "";
            for (var i in userDeviceArray) {
                if (userDeviceArray[i].platform.test(currentPlatform)) {
                    device = userDeviceArray[i].device;
                    break;
                }
            }
            if (device === "Macintosh" && "ontouchend" in document) {
                device = "iPad";
            }
            var deviceInfo = {
                deviceType: device ? (["Linux", "Windows", "Macintosh"].includes(device) ? "desktop" : "mobile") : "unknown",
                deviceOS: device || "unknown",
                browserName: getBrowserName(),
            };
            localStorage.setItem("deviceInfo", JSON.stringify(deviceInfo));
            state.deviceInfo = deviceInfo;
        },
        changeCity: function (state, action) {
            localStorage.setItem("city", JSON.stringify(action.payload));
            state.city = action.payload;
        },
        changeHeaderVisibility: function (state, action) {
            state.isHeaderShown = action.payload;
        },
        changeFooterVisibility: function (state, action) {
            state.showFooter = action.payload;
        },
        changeAuthModalVisibility: function (state, action) {
            state.isAuthModalShown = action.payload;
        },
        changeAppNotifyVisibility: function (state, action) {
            state.appNotify = action.payload;
        },
        addNotification: function (state, action) {
            var notificationData = action.payload;
            var notificationList = __spreadArray([], __read(state.notifications), false);
            var newNotification = { id: notificationData.id, content: notificationData.content };
            var currentCategory = notificationList.find(function (prevNotification) { return prevNotification.category === notificationData.category; });
            if (currentCategory) {
                currentCategory.value.push(newNotification);
                var idx = notificationList.findIndex(function (el) { return el.category === notificationData.category; });
                notificationList.splice(idx, 1, currentCategory);
            }
            else {
                notificationList.push({ category: notificationData.category, value: [newNotification] });
            }
            localStorage.setItem("notifications", JSON.stringify(notificationList));
            state.notifications = notificationList;
        },
        removeNotificaionCategory: function (state, action) {
            var filterredNotifications = state.notifications.filter(function (prevNotification) { return prevNotification.category !== action.payload; });
            localStorage.setItem("notifications", JSON.stringify(filterredNotifications));
            state.notifications = filterredNotifications;
        },
        rewriteNotifications: function (state, action) {
            action.payload.withLocalSave && localStorage.setItem("notifications", JSON.stringify(action.payload.data));
            state.notifications = action.payload.data;
        },
        saveDefaultFooterLinks: function (state, action) {
            state.defaultFooterLinks = action.payload;
        },
        changeFooterLinks: function (state, action) {
            state.footerLinks = action.payload.length > 0 ? action.payload : state.defaultFooterLinks;
        },
        saveBreakpointMatches: function (state, action) {
            state.breakpointMatches = __assign(__assign({}, state.breakpointMatches), action.payload);
        },
        saveOptionalBlocks: function (state, action) {
            state.optionalBlocks = action.payload;
        },
        changeFooterIntersectionStatus: function (state, action) {
            state.isFooterIntersected = action.payload;
        },
        changeFlexGapSupportStatus: function (state, action) {
            state.isFlexGapSupported = action.payload;
        },
        rewriteUnvisitedOrders: function (state, action) {
            localStorage.setItem("unvisitedOrders", action.payload.toString());
            state.unvisitedOrders = action.payload;
        },
        changeClientWidgetSubClass: function (state, action) {
            state.clientWidgetSubClass = action.payload;
        },
        changeSnackbarData: function (state, action) {
            if (JSON.stringify(state.snackbarData) === JSON.stringify(action.payload))
                return;
            state.snackbarData = action.payload;
        },
        changeIpFriendlinessStatus: function (state, action) {
            state.isFriendlyIp = action.payload;
        },
        saveExperiments: function (state, action) {
            state.AB_tests = action.payload;
        },
        savePromoActions: function (state, action) {
            state.promoActions = action.payload;
        },
        changeDiscountIncrease: function (state, action) {
            state.discountIncrease = action.payload;
        },
    },
});
export var addNotification = (_a = appSlice.actions, _a.addNotification), changeAppNotifyVisibility = _a.changeAppNotifyVisibility, changeAuthModalVisibility = _a.changeAuthModalVisibility, changeCity = _a.changeCity, changeClientWidgetSubClass = _a.changeClientWidgetSubClass, changeFlexGapSupportStatus = _a.changeFlexGapSupportStatus, changeFooterIntersectionStatus = _a.changeFooterIntersectionStatus, changeFooterLinks = _a.changeFooterLinks, changeFooterVisibility = _a.changeFooterVisibility, changeGender = _a.changeGender, changeHeaderVisibility = _a.changeHeaderVisibility, changeIpFriendlinessStatus = _a.changeIpFriendlinessStatus, changeSnackbarData = _a.changeSnackbarData, identifyDevice = _a.identifyDevice, removeNotificaionCategory = _a.removeNotificaionCategory, rewriteNotifications = _a.rewriteNotifications, rewriteUnvisitedOrders = _a.rewriteUnvisitedOrders, saveBreakpointMatches = _a.saveBreakpointMatches, saveDefaultFooterLinks = _a.saveDefaultFooterLinks, saveExperiments = _a.saveExperiments, saveMainMenu = _a.saveMainMenu, saveOptionalBlocks = _a.saveOptionalBlocks, savePromoActions = _a.savePromoActions, changeDiscountIncrease = _a.changeDiscountIncrease;
export default appSlice.reducer;
